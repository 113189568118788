import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FacilitySettingSavedPermitDataService {
  constructor(private http: HttpClient) {}

  create(
    TableName: string,
    FacilityID,
    itemType: string,
    PermitGroupID,
    VarietyTypeID,
    StatusID,
    StatusActionID,
    StatusActionStatusID,
    SettingID,
    SettingValue: any,
    Attribute: any,
    IsStandard: any,
    Config?: any): Observable<any> {

    const body = {
      TableName,
      FacilityID,
      itemType,
      PermitGroupID,
      VarietyTypeID,
      StatusID,
      StatusActionID,
      StatusActionStatusID,
      SettingID,
      SettingValue,
      Attribute,
      IsStandard,
      Config
    };
    return this.http.post(`facilitySettingSavedPermit/create`, body);
  }

  edit(facilitySettingSavedID: number, tableName: string, FacilityID: number, settingObject: any): Observable<any> {
    const {
      itemType,
      permitGroupID,
      varietyTypeID,
      statusID,
      statusActionID,
      statusActionStatusID,
      ID,
      settingID,
      settingValue,
      attribute,
      isStandard,
      config
    } = settingObject;

    const body = {
      facilitySettingSavedID,
      tableName,
      FacilityID,
      itemType,
      permitGroupID,
      varietyTypeID,
      statusID,
      statusActionID,
      statusActionStatusID,
      ID,
      settingValue,
      attribute,
      isStandard,
      settingID,
      config: config ?? ''
    };
    return this.  http.put(`facilitySettingSavedPermit/edit`, body);
  }

  delete(facilitySettingSavedID: number) {
    const params = {
      TableName: 'FacilitySettingSavedPermit',
      facilitySettingSavedID
    };
    return this.http.delete(`facilitySettingSavedPermit/delete`, { params: params as any });
  }

  view(facilitySettingSavedID: number) {
    const params = { facilitySettingSavedID };
    return this.http.get(`facilitySettingSavedPermit/view`);
  }

  getID(
    tableName: string,
    facilityID: number,
    permitType: string,
    varietyTypeID: number,
    statusID: number,
    statusActionID: number,
    statusActionStatusID: number,
    settingID: number
  ) {
    const params = {
      tableName,
      facilityID,
      permitType,
      varietyTypeID,
      statusID,
      statusActionID,
      statusActionStatusID,
      settingID
    };
    return this.http.get(`facilitySettingSavedPermit/getID`);
  }

  getRecordsForFacilityID(TableName: string, facilityID: number, pagingParams?: { pageNumber: number; pageSize: number; }): Observable<any[]> {
    let params: any = {
      TableName,
      FacilityID: `${facilityID}`
    };

    if (pagingParams) {
      params = {
        ...params,
        pageNumber: pagingParams.pageNumber,
        pageSize: pagingParams.pageSize
      };
    }

    return this.http.get<any[]>(`facilitySettingSavedPermit/getRecordsForFacilityID`, {
      params
    });
  }

  getRecordsForSettingID(tableName: string, facilityID: number, settingID: number) {
    const params = {
      tableName,
      facilityID,
      settingID
    };
    return this.http.get(`facilitySettingSavedPermit/getRecordsForSettingID`, { params: params as any });
  }

  getRecordsForSettingIDAndItemType(tableName: string, facilityID: number, settingID: number, itemType: string, isStandard: boolean) {
    const params = {
      tableName,
      facilityID,
      settingID,
      itemType,
      isStandard
    };
    return this.http.get(`facilitySettingSavedPermit/getRecordsForSettingIDAndItemType`, { params: params as any });
  }

  getRecordsForStatusActionStatusID(tableName: string, facilityID: number, statusActionStatusID: number) {
    const params = {
      tableName,
      facilityID,
      statusActionStatusID
    };
    return this.http.get(`facilitySettingSavedPermit/getRecordsForStatusActionStatusID`);
  }

  getRecordsForStatusID(tableName: string, facilityID: number, statusID: number) {
    const params = {
      tableName,
      facilityID,
      statusID
    };
    return this.http.get(`facilitySettingSavedPermit/getRecordsForStatusID`);
  }

  getRecordsForVarietyTypeID(tableName: string, facilityID: number, varietyTypeID: number) {
    const params = {
      tableName,
      facilityID,
      varietyTypeID
    };
    return this.http.get(`facilitySettingSavedPermit/getRecordsForVarietyTypeID`);
  }

  cloneVarietyTypeSettings(cloneParams: CloneSettingsParams) {

    let functionName = cloneParams.sourceSettingID ? 'cloneVarietyTypeSetting' : 'cloneVarietyTypeSettings';

    return this.http.get(`facilitySettingSavedPermit/${functionName}`, { params: cloneParams as any });
  }

  cloneStatusSettings(cloneParams: CloneSettingsParams) {
    let functionName = cloneParams.sourceSettingID ? 'cloneStatusSetting' : 'cloneStatusSettings';

    return this.http.get(`facilitySettingSavedPermit/${functionName}`, { params: cloneParams as any });
  }

  cloneStatusActionSettings(cloneParams: CloneSettingsParams) {
    let functionName = cloneParams.sourceSettingID ? 'cloneStatusActionSetting' : 'cloneStatusActionSettings';

    return this.http.get(`facilitySettingSavedPermit/${functionName}`, { params: cloneParams as any });
  }

  cloneStatusActionStatusSettings(cloneParams: CloneSettingsParams) {
    let functionName = cloneParams.sourceSettingID ? 'cloneStatusActionStatusSetting' : 'cloneStatusActionStatusSettings';
    return this.http.get(`facilitySettingSavedPermit/${functionName}`, { params: cloneParams as any });
  }


  reorderSettingFromList(
    controller: string,
    itemList: string[],
    settingID: number,
    isStandard: boolean,
    tableName: string,
    facilityId: number,
    itemType: string,
    permitGroupId: number,
    statusId: number,
    statusActionId: number,
    statusActionStatusId: number,
    varietyTypeId: number
  ): Observable<any> {


    const body = {
      settingID,
      isStandard,
      tableName: tableName,
      facilityId: facilityId,
      itemType: itemType,
      permitGroupId: permitGroupId,
      statusId: statusId,
      statusActionId: statusActionId,
      statusActionStatusId: statusActionStatusId,
      varietyTypeId: varietyTypeId,
      itemList: itemList
    };

    return this.http.put(`${controller}/reorderSettingFromList`, body);
  }

}

export interface CloneSettingsParams {
    tableName: string;
    sourceVarietyTypeID: number;
    destinationVarietyTypeID: number;
    sourceSettingID?: number;
    sourceStatusID?: number;
    destinationStatusID?: number;
    sourceStatusActionID?: number;
    destinationStatusActionID?: number;
    sourceStatusActionStatusID?: number;
    destinationStatusActionStatusID?: number;
  }
